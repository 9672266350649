<template>
  <!-- Jumbotron -->
  <div class="p-3 text-center bg-white border-bottom">
    <div class="container">
      <div class="row gy-3">
        <!-- Left elements -->
        <div class="col-lg-1 col-sm-4 col-4">
          <a
            href="/"
            target="_blank"
            class="float-start"
          >
            <img
              src="/logo.png"
              height="40"
            />
          </a>
        </div>
        <!-- Left elements -->

        <!-- Center elements -->
        <div class="order-lg-last col-lg-6 col-sm-8 col-8">
          <div class="d-flex float-end">
            <router-link
              to="/"
              class="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
            >
              <i class="fas fa-home m-1 me-md-2"></i>
              <p class="d-none d-md-block mb-0">Inicio</p>
            </router-link>
            <router-link
              to="/productos"
              class="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
            >
              <i class="fas fa-box m-1 me-md-2"></i>
              <p class="d-none d-md-block mb-0">Productos</p>
            </router-link>
            <router-link v-if="!storeUser.session"
              to="/login"
              class="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
            >
              <i class="fas fa-user-alt m-1 me-md-2"></i>
              <p class="d-none d-md-block mb-0">Iniciar Sesion</p>
            </router-link>
            <router-link v-else
              to="/mi-cuenta"
              class="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
            >
              <i class="fas fa-user-alt m-1 me-md-2"></i>
              <p class="d-none d-md-block mb-0">Mi Cuenta</p>
            </router-link>
            <router-link
              to="/carrito"
              class="border rounded py-1 px-3 nav-link d-flex align-items-center"
            >
              <i class="fas fa-shopping-cart m-1 me-md-2"></i>
              <p class="d-none d-md-block mb-0">Carrito</p>
              <span class="badge badge-danger ms-2" v-if="totalCarrito > 0">{{ totalCarrito }}</span>
            </router-link>
          </div>
        </div>
        <!-- Center elements -->

        <!-- Right elements -->
        <div class="col-lg-5 col-md-12 col-12"></div>
        <!-- Right elements -->
      </div>
    </div>
  </div>
  <!-- Jumbotron -->

</template>
<script setup>
import {computed} from 'vue'
import { useAuthStore } from "@/stores/useAuthStore";
import {useCarritoStore} from "@/stores/useCarritoStore"
const storeUser = useAuthStore();
const productosStore = useCarritoStore()

const totalCarrito = computed(() => {
  const valor = parseInt(productosStore.productos.length)
  return valor;
});
</script>