import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useAuthStore } from "@/stores/useAuthStore";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: function () {
      return import("../views/LoginView.vue");
    },
  },
  {
    path: "/register",
    name: "register",
    component: function () {
      return import("../views/RegisterView.vue");
    },
  },
  {
    path: "/productos",
    name: "productos",
    component: function () {
      return import("../views/ProductosView.vue");
    },
  },
  {
    path: "/producto/:idProducto",
    name: "producto",
    component: function () {
      return import("../views/DetalleProductoView.vue");
    },
  },
  {
    path: '/carrito',
    name: 'carrito',
    component: function () {
      return import('../views/CarritoView.vue')
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: function () {
      return import('../views/CheckoutView.vue')
    }
  },
  {
    path: '/transaccionStatus/:idOrden',
    name: 'transaccion-status',
    component: function () {
      return import('../views/TransaccionStatusView.vue')
    }
  },
  {
    path: "/mi-cuenta",
    name: "mi-cuenta",
    component: function () {
      return import("../views/Cuenta/Index.vue");
    },
  },
  {
    path: "/mi-cuenta/mis-ordenes",
    name: "mi-cuenta-ordenes",
    component: function () {
      return import("../views/Cuenta/Ordenes.vue");
    },
  },
  {
    path: "/mi-cuenta/mis-ordenes/detalle/:idOrden",
    name: "mi-cuenta-ordenes-detalle",
    component: function () {
      return import("../views/Cuenta/DetalleOrden.vue");
    },
  },
  {
    path: "/mi-cuenta/mi-perfil",
    name: "mi-cuenta-perfil",
    component: function () {
      return import("../views/Cuenta/Perfil.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
