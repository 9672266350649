import { defineStore } from 'pinia'

export const useCarritoStore = defineStore('carrito', {
  state: () => ({
    productos: []
  }),
  persist: true,
  getters: {
    totales() {
      let total = 0;
      this.productos.forEach((product) => {
        total = parseInt(total) + parseInt(product.total)
      })
      return total
    }
  },
  actions: {
    clearCart()
    {
      this.productos = []
    },
    addProductToCartQty(producto, qty){
      producto.qty = qty
      producto.total = qty * producto.price
      this.productos.push(producto)
      console.log("agregando")
    },
    deleteProductToCart(index) {
      let productos = this.productos
      console.log(index)
      productos.splice(index, 1)
      console.log(productos.length)
      console.log("producto " + this.productos[index].name)
      this.productos = productos
      console.log(this.productos.length)
    },
    updateQtyProductCart(index, tipo) {
      if (tipo == 'suma') {
        this.productos[index].qty += 1
        this.productos[index].total = parseInt(this.productos[index].qty * this.productos[index].price)
      }
      else{
        this.productos[index].qty -= 1
        console.log(parseInt(this.productos[index].qty * this.productos[index].price_unitary))
        this.productos[index].total = parseInt(this.productos[index].qty * this.productos[index].price)
      }
    },
  },
})