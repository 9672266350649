<template>
  <!--Main Navigation-->
  <header>
    <LayoutsHeader />
    <LayoutsJumbo />
  </header>
  <!-- Products -->
  <section>
    <div class="container my-5">
      <header class="mb-4">
        <h3>Nuevos Productos</h3>
      </header>

      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6 d-flex" v-for="(producto, index) in productos" :key="index">
          <div class="card w-100 my-2 shadow-2-strong">
            <router-link :to="'producto/'+producto.id">
            <img
            :src="getImagen(producto.imagen.image)"
              class="card-img-top"
              style="aspect-ratio: 1 / 1"
            />
          </router-link>
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">{{producto.name}}</h5>
              <p class="card-text text-end">$ {{ producto.price }}</p>
              <div
                class="card-footer d-flex align-items-end pt-3 px-0 pb-0 mt-auto"
              >
                <button @click="addProductToCartQty(producto, 1)" class="btn btn-primary shadow-0 me-1 w-100">Agregar Al Carrito</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!-- Products -->
  <LayoutsFeature />
  <LayoutsBlog />
  <LayoutsFooter />
</template>

<script setup>
import {ref} from 'vue'
import api from '@/utils/api'
import LayoutsHeader from "@/components/Layouts/Header.vue";
import LayoutsFooter from "@/components/Layouts/Footer.vue";
import LayoutsJumbo from "@/components/Layouts/Jumbo.vue";
import LayoutsBlog from "@/components/Layouts/Blog.vue";
import LayoutsFeature from "@/components/Layouts/Feature.vue";

import {useCarritoStore} from "@/stores/useCarritoStore"
const productosStore = useCarritoStore()
const { addProductToCartQty } = productosStore

const productos = ref([])
const getProducts = async () => {
  api.get('productsHome').then((response) => {
    console.log(response.data)
    productos.value= response.data.data
  })
};

getProducts()

const getImagen = (imagen) => {
  return "https://api.impresosgalarce.cl/uploads/imagesProductos/"+imagen
}
</script>
